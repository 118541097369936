<script>
import {Bar} from "vue-chartjs";
import {SOLUTIA} from "@/constants/Colors";

export default {
  name: "DossierCount",
  extends: Bar,
  props: {
    data: Object,
    height: {
      type: Number,
      default: 400,
    },
    width: {
      type: Number,
      default: 400,
    }
  },
  mounted: async function () {
    let statusFetchStatus = this.$store.getters["dossier/statuts/statut_fetch_status"];
    if (statusFetchStatus === "INITIAL" || statusFetchStatus === "ERROR") {
      await this.$store.dispatch("dossier/statuts/getStatuts");
    }
    const chartData = {
      label: "Nombre de dossiers par étape",
      labels: Object.keys(this.data),
      datasets: [{
        data: Object.values(this.data),
        backgroundColor: SOLUTIA
      }],
    }
    const config = {
      onClick: this.handleStatutClick,
      title: {
        display: true,
        text: "Nombre de dossiers par étape",
        fontSize: 16,
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          },
          gridLines: {
            drawBorder: false,
          }
        }],
        xAxes: [{
          gridLines: {
            display: false,
          }
        }]
      }
    }
    this.renderChart(chartData, config);
  },
  computed: {
    statuts() {
      return this.$store.getters["dossier/statuts/statuts"];
    }
  },
  methods: {
    handleStatutClick(point, elementClicked) {
      if (elementClicked.length > 0) {
        let element = elementClicked[0];
        let statutName = Object.keys(this.data)[element._index];
        let statut = this.statuts.find((statut) => statut.name === statutName);
        this.$router.push(`/dossier?statut=${statut.id}`);
      }
    }
  }
}
</script>

<style scoped>

</style>