import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"primary--text text-center"},[_vm._v("Documents expirés")]),(!_vm.loading)?_c(VSheet,{staticClass:"overflow-y-auto"},_vm._l((_vm.expiredDocuments),function(document){return _c(VCard,{key:document.id,staticClass:"mb-2",attrs:{"color":"white","elevation":"1"}},[_c(VCardTitle,{staticClass:"pb-0 primary--text"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":("/entreprises/" + (document.entreprise))}},[_vm._v(_vm._s(document.type))])],1),_c(VCardText,{staticClass:"pb-0"},[_c('p',{staticClass:"body-1 mb-0 black--text"},[_vm._v("Expiré depuis "+_vm._s(_vm.getReadableDate(document.date_validite)))])]),_c(VCardActions,[_c(VSpacer),_c('router-link',{attrs:{"to":("/entreprises/" + (document.entreprise))}},[_vm._v(_vm._s(document.entreprise_name))])],1)],1)}),1):_c(VRow,{staticClass:"align-content-stretch",staticStyle:{"height":"400px"},attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c(VProgressCircular,{attrs:{"color":"primary","indeterminate":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }