<template>
  <v-container>
    <v-row class="mt-2" justify="center">
      <h2 class="primary--text">Tableau de bord</h2>
    </v-row>
    <v-row v-if="isIntern">
      <v-col cols="12" md="6">
        <v-card @click="showProspects" color="grey" class="ma-4 pa-3 text-center">
          <h2 class="white--text" v-if="loading === false">{{ stats.dossiers_prospects }} <br> prospects</h2>
          <v-progress-circular indeterminate v-else color="white" class="justify-center align-center"/>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card to="/dossier?arrete=absent" color="blue" class="ma-4 pa-3 text-center">
          <h2 class="white--text" v-if="loading === false">{{ stats.dossiers_actifs }} <br> dossier(s) en cours sans
            arrêtés</h2>
          <v-progress-circular indeterminate v-else color="white" class="justify-center align-center"/>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card color="primary" :to="'/dossier?arrete=' + current_year.toString()" class="ma-4 pa-3 text-center">
          <h2 class="white--text" v-if="loading === false">{{ stats.dossiers_arretes }} <br> dossier(s) avec arrêtés en
            {{ current_year }}</h2>
          <v-progress-circular indeterminate v-else color="white" class="justify-center align-center"/>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card @click="showChantier" color="#11C9B2" class="ma-4 pa-3 text-center">
          <h2 class="white--text" v-if="loading === false">{{ stats.dossiers_chantiers }} <br> dossier(s) en chantier
          </h2>
          <v-progress-circular indeterminate v-else color="white" class="justify-center align-center"/>
        </v-card>
      </v-col>
      <!--
      <v-col cols="12" md="4">
        <v-card color="red" class="ma-4 pa-3 text-center">
          <h2 class="white--text" v-if="loading === false">{{ stats.montant_travaux }} € <br> dû en travaux</h2>
          <v-progress-circular indeterminate v-else color="white" class="justify-center align-center"/>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card color="orange" class="ma-4 pa-3 text-center">
          <h2 class="white--text" v-if="loading === false">{{ stats.montant_honoraires }} € <br> dû en honoraires</h2>
          <v-progress-circular indeterminate v-else color="white" class="justify-center align-center"/>
        </v-card>
      </v-col>-->
    </v-row>
    <v-row justify="center" no-gutters align-content="center" v-if="loading === false && isIntern">
      <v-col cols="4">
        <dossier-count style="max-height: 400px; max-width: 400px;" :height="400" :width="400"
                       :data="stats.etat_dossiers"/>
      </v-col>
      <v-col cols="4">
        <v-carousel :show-arrows="true" hide-delimiters :cycle="true" height="400">
          <v-carousel-item v-for="year_stat of stats.year_stats" :key="year_stat.year">
            <v-row no-gutters justify="center" align="center">
              <h2 class="primary--text">Objectifs de {{ year_stat.year }}</h2>
            </v-row>
            <v-row justify="center" align="center">

              <v-progress-circular width="15" size="200" color="primary" :value="getPercentage(year_stat)">
                {{ year_stat.count }} / {{ year_stat.goal }}<br>
                {{ getPercentage(year_stat) + " %" }}
              </v-progress-circular>
            </v-row>
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col cols="4">
        <ImportantMessages/>
      </v-col>

    </v-row>

    <v-row class="mb-4" v-if="isIntern" :justify="selected_marker ? 'space-around' : 'center'" align-content="center">
      <Map
          :center="{lat:14.6503419, lng:-61.0238895}"
          :zoom="10"
          style="width: 600px; height: 400px; align-content: center"
      >
        <gmap-marker v-for="marker in markers" :key="marker.dossier_id"
                     :position="{lat: marker.latitude, lng: marker.longitude}"
                     @click="setMarker($event, marker)"
        />
      </Map>
    </v-row>
    <v-menu :close-on-click="false" v-model="showMenu" bottom absolute :position-x="menuPosition.x"
            :position-y="menuPosition.y">
      <v-card v-if="selected_marker">
        <v-card-title>
          <span>{{ selected_marker.beneficiaire_name }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="showMenu = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <p>
            <span class="body-1 font-weight-bold">Ville</span><br>
            <span class="body-2">{{ selected_marker.ville }}</span><br><br>
            <span class="body-1 font-weight-bold">Statut</span><br>
            <v-chip color="primary">{{ selected_marker.statut }}</v-chip>

          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="goToDossier(selected_marker)">Voir le dossier</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <v-row no-gutters v-if="isIntern" justify="space-around">
      <v-col md="4" cols="12">
        <ExpiredDocuments/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {Map, Marker} from "vue2-google-maps";
import {DOSSIER_ETATS} from "@/constants/DossierStatus";
import DossierCount from "@/components/home/DossierCount";
import dayjs from "dayjs";
import ImportantMessages from "@/views/ImportantMessages";
import ExpiredDocuments from "@/views/ExpiredDocuments.vue";
import {AuthMixin} from "@/mixins/AuthMixin";

export default {
  name: 'Home',
  mixins: [AuthMixin],
  async mounted() {
    if (this.logged_user && this.isIntern) {
      this.stats = await this.$store.dispatch("dossier/getStats");
    }
    this.loading = false;
  },
  components: {
    ExpiredDocuments,
    ImportantMessages,
    DossierCount,
    Map,
    'gmap-marker': Marker,
  },
  data() {
    return {
      stats: null,
      loading: true,
      selected_marker: null,
      showMenu: false,
      menuPosition: {x: null, y: null},
    }
  },
  computed: {
    markers() {
      return this.stats != null ? this.stats.dossiers.map(dossier => ({
        ...dossier,
        latitude: parseFloat(dossier.latitude),
        longitude: parseFloat(dossier.longitude)
      })) : null;
    },
    statuts() {
      return this.$store.getters["dossier/statuts/statuts"];
    },
    count_dossier_per_etat() {
      return {
        options: {
          chart: {
            id: 'dossier-par-etat',
            type: "pie",
          },
          dataLabels: {
            position: "bottom",
          },
          theme: {
            palette: "palette1",
          },
          labels: this.etat_dossiers.categories,
          xaxis: {
            categories: this.etat_dossiers.categories
          }
        },
        series: [{
          name: 'Nombre de dossiers',
          data: this.etat_dossiers.values,
        }]
      }
    },
    etat_dossiers() {
      if (this.stats === null) {
        return {categories: [], values: []};
      }
      let categories = []
      let values = []
      for (let etat in this.stats.etat_dossiers) {
        categories.push(DOSSIER_ETATS[etat]);
        values.push(this.stats.etat_dossiers[etat]);
      }
      return {categories, values};
    },
    current_year() {
      return dayjs().year();
    },
  },
  methods: {
    getPercentage(stat) {
      return ((stat.count / stat.goal) * 100).toFixed(0)
    },
    goToDossier(marker) {
      this.$router.push('/dossier/' + marker.dossier_id);
    },
    setMarker(event, marker) {
      this.showMenu = false;
      this.menuPosition.x = event.domEvent.clientX;
      this.menuPosition.y = event.domEvent.clientY;
      this.selected_marker = marker;
      this.$nextTick(() => {
        this.showMenu = true
      });
    },
    showProspects() {
      let statusLoaded = this.$store.getters["dossier/statuts/statut_fetch_status"] === "LOADED";
      if (!statusLoaded) return;
      this.$router.push(`/dossier?statut=prospects`);
    },
    showChantier() {
      this.$router.push(`/dossier?statut=chantier`);
    }

  }
}
</script>
